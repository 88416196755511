* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    /* overflow-x: hidden; */
}
.divContact{
    margin: 15vh 0 0 0;
    width: 100%;
    max-width: 100vw;
    min-height: 100vh;
}
.contactForm{
    width: 100%;
    max-width: 100vw;
    padding: 3% 10%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    background: rgb(255, 255, 255);
    align-items: center;
}
.divFirstTextContact{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.firstTextContact{
    font-size: 21px;
    font-family: Arial;
}
.divEmailUs{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 1% 0;
    background: rgb(255, 15, 75);
    width: 33vw;
    height: 80%;
    position: absolute;
    padding: 2% 3%;
    box-shadow: 0px 2px 19px 7px rgba(0,0,0,0.1);
    border-radius: 5px;
}
.getInTouch{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 2vw;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 1%;
}
.mailContact{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 1.4vw;
    font-weight: bolder;
    color: rgb(253, 253, 253);
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
.textContactUs{
    width: 100%;
    display: flex;
    font-size: 1.1vw;
    font-weight: 400;
    color: rgb(253, 253, 253);
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    margin-bottom: 5%;

}
.contactUs{
    width: 100%;
    display: flex;
    justify-content: left;
    font-size: 3vw;
    font-weight: bold;
    color: rgb(255, 255, 255);
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    margin-bottom: 3%;
}
.gmailIcon{
    width: 3.5vw;
    height: 3.5vw;
}
.divFormContact{
    background: rgb(255, 255, 255);
    width: 90%;
    margin: 0 0 0 20%;
    padding: 7% 0 7% 23%;
    box-shadow: 0px 2px 19px 7px rgba(0,0,0,0.1);
    /* height: 110vh; */
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.titleContactText{
    width: 100%;
    display: flex;
    font-size: 1.1vw;
    font-weight: 400;
    color: rgb(24, 24, 24);
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    margin-bottom: 5%;
}
.userInfoContact{
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0;
}
.divFirstInfo{
    display: inline-block;
    width: 50%;
    /* background: rgb(255, 255, 255); */
    padding-right: 2%;
}
.div2ndInfo{
    display: inline-block;
    width: 98%;
    /* background: rgb(255, 255, 255); */
}
.inputContact{
    width: 100%;
    height: 7vh;
    border: none;
    background: rgb(248, 248, 248);
    padding: 0 10px;
    border-radius: 5px;
    margin-bottom: 2%;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 1vw;
    font-weight: 400;
}
.areaContact{
    width: 98%;
    height: 13vh;
    border: none;
    background: rgb(248, 248, 248);
    padding: 0 10px;
    border-radius: 5px;
    margin-bottom: 2%;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2vw;
    font-weight: 400;
}
.SendContactForm{
    width: 50%;
    border: none;
    background: rgb(255, 15, 75);
    border-radius: 5px;
    color: rgb(248, 248, 248);
    padding: 13px 10px;
    margin-top: 5%;
    font-size: 1.2vw;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}
.error{
    border: 1px solid rgb(255, 44, 72);
}
.phoneContact{
    margin: 1% 0 1% 0;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    color: rgb(94, 94, 94);
    font-weight: 600;
}
@media screen and (max-width: 1034px) {
    .contactForm{
        width: 100%;
        max-width: 100vw;
        padding: 2%;
        display: block;
    }
    .divEmailUs{
        width: 100%;
        display: inline-flex;
        width: 100%;
        /* height: 200px; */
        position: relative;
        padding: 2% 3%;
    }
    .mailContact{
        font-size: 13px;
    }
    .textContactUs{
        font-size: 12px;
    }
    .contactUs{
        width: 100%;
        font-size: 25px;
    }
    .gmailIcon{
        width: 30px;
        height: 30px;
    }
    .divFormContact{
        background: rgb(255, 255, 255);
        width: 100%;
        margin: 3% 0%;
        padding: 3% 4%;
        box-shadow: 0px 2px 19px 7px rgba(0,0,0,0.1);
        /* height: 530px; */
    }
    .getInTouch{
        font-size: 20px;
        margin-bottom: 3%;
    }
    .SendContactForm{
        width: 90%;
        font-size: 2.5vw;
    }
    .titleContactText{
        font-size: 3vw;
    }
    .inputContact{
        font-size: 2vw;
    }
    .areaContact{
        font-size: 2.5vw;
    }
    .telDevise{
        font-size: 2.5vw !important;
    }
}