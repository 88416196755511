* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* overflow-x: hidden; */
}
.divFooter{
    width: 100%;
    background: rgb(2, 10, 26);
    padding: 1% 10%;
    display: flex;
    justify-content: space-between;
    color: whitesmoke;
    bottom: 0;
    margin-bottom: 0;
    
}
.copyrightGraphix{
    font-weight: 600;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    color: rgb(255, 254, 250);
    /* background:chartreuse; */
    width: 30%;
    justify-content: center;
    align-items: center;
    font-weight: 400;
}
.divLogoFooter{
    display: flex;
    color: rgb(255, 254, 250);
    /* background:chartreuse; */
    width: 30%;
    justify-content: center;
    align-items: center;
}
.divIconFooter{
    display: flex;
    color: rgb(255, 254, 250);
    /* background:chartreuse; */
    width: 30%;
    justify-content: center;
    align-items: center;
    font-size: 35px;
}
.imgFooter{
    width: 130px;
    height: 90px;
}
.divIconFooter a{
    margin-right: 3%;
}
.iconFoot{
    margin: 0 2%;
    color: white;
    text-decoration: none;
}
@media screen and (max-width: 1034px) {
    .copyrightGraphix{
        font-size: 10px;
    }
    .divIconFooter{
        font-size: 20px;
    }
    .divIconFooter a{
        margin-right: 5%;
    }
    .imgFooter{
        width: 90px;
        height: 60px;
    }
}