* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* overflow-x: hidden; */
}

nav {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 8vh;
    background-color: #395fac;
    font-family: 'Titillium Web', sans-serif;
    z-index: 100;
    overflow-x: hidden;
}

.logo {
    width: 30%;
    display: flex;
    text-align: left;
    /* background-color: aqua; */
    padding: 0 3vh;
}
.logoHeader{
    height: 7vw;
    width: 7vw;
    object-fit: cover;
}
.nav-links {
    display: flex;
    justify-content: space-around;
    width: 50%;
    /* background-color: rgb(141, 25, 97); */
    margin: 1vh 3vh;

}

.nav-links li {
    list-style: none;
    padding-top: 1vh;
}

.nav-links a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    letter-spacing: 3px;
    font-weight: normal;
    font-size: 1.3vw;
}

.burger {
    display: none;
}

.burger div {
    width: 25px;
    height: 3px;
    background-color: rgb(255, 255, 255);
    margin: 5px;
    transition: all 0.3s ease;
}
.li-nav-start{
    padding: 1vh 4.5vh;
    background: rgb(255, 17, 88);
    color: beige;
    transition: 0.3s ease-in;
}
.li-nav-start:hover{
    background: rgb(243, 243, 243);
    border: 1px solid rgb(255, 17, 88);
    transition: 0.3s ease-in;
}
.li-nav-start:hover a{
    color: rgb(255, 17, 88);
    transition: 0.3s ease-in;
}
@media screen and (max-width: 1024px) {
    .nav-links {
        width: 60%;
    }
    body {
        overflow-x: hidden;
    }
    .logoHeader{
        height: 15vw;
        width: 15vw;
        object-fit: cover;
    }
}

@media screen and (max-width: 768px) {
    body {
        overflow-x: hidden;
    }
    .nav-links {
        position: fixed;
        right: -10%;
        height: 100vh;
        top: 0;
        margin: 0% 0%;
        background-color: #0c0f16;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 110vw;
        transform: translateX(110vw);
        transition: transform 0.5s ease-in;
        justify-content: center;
        z-index: 121;
    }
    .nav-links li {
        opacity: 0;
        margin: 5vh 0;
    }
    .nav-links a {
        color: rgb(211, 211, 211);
        font-size: 4vw;
    }
    
    .burger {
        display: block;
        cursor: pointer;
        right: 0%;
        z-index: 122;
    }
    nav {
        max-width: 100vw;
        display: flex;
        overflow-x: hidden;
    }
}

.nav-active {
        transform: translateX(0%);
}

@keyframes navLinkFade {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
    opacity: 0;
}

.toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
}