body{
    margin: 0;
    padding: 0%;
    width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
}
/* .divBigAbout{
    margin: 6% 0 0 0;
    overflow-x: hidden;
    width: 100%;
    max-width: 100vw;
} */
.divImgTopAbout{
    width: 100%;
    height: 630px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
}
.imageTop{
    width: 100%;
    height: 750px;
    transform: scale(1);
    object-fit: cover;
}
.coverTopAbout{
    position:absolute;
    z-index: 2;
    width: 100%;
    height: 630px;
    background: rgba(131, 131, 131, 0.158);
    
}
.coverTopAbout2{
    position:absolute;
    z-index: 2;
    width: 100%;
    height: 500px;
    background: rgba(19, 19, 19, 0.76);
}
.textInsideTop{
    position:absolute;
    z-index: 4;
    bottom: 0;
    left: 0;
    margin-bottom: 17%;
    margin-left: 7%;
    max-width: 100vw;
    overflow-y: hidden;
    display: block;
    color: rgb(245, 245, 245);
    width: 65%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 20px;
}
.titleInsideTopAbout{
    font-size: 60px;
    color: white;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}
.divQualitiesListAbout{
    width: 100%;
    padding: 5% 5% 1% 5%;
    /* background: rosybrown; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.div1stQualityAbout{
    width: 27vw;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin: 3% 1%;
}
.divImageQualityAbout{
    width: 100%;
    height: 55vh;
    background: rgb(226, 226, 226);
    display: flex;
    overflow-y: hidden;
}
.coverImgAbout{
    position: absolute;
    width: 0;
    z-index: 1;
    background: rgba(17, 17, 17, 0);
    height: 55vh;
    transition: 0.5s ease-in-out;
}
.div1stQualityAbout:hover .coverImgAbout{
    position: absolute;
    width: 27vw;
    z-index: 2;
    background: rgba(17, 17, 17, 0.507);
    height: 55vh;
    transition: 0.5s ease-in-out;
}
.divLegendQualityAbout{
    position: absolute;
    width: 20vw;
    z-index: 3;
    margin-top:50vh;
    transition: 0.5s;
    margin-left: 2%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    transform: translateY(0%);
    font-size: 1.3vw;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    /* background: red; */

}
.div1stQualityAbout:hover .divLegendQualityAbout{
    position: absolute;
    width: 100%;
    z-index: 3;
    transform: translateY(-50px);
    transition: 0.5s ;
}
.imageQualitiesAbout{
    width: 100%;
    height: 100%;
    transform: scale(1.0);
    object-fit: cover;
}
.firstTextAbout{
    width: 100%;
    padding: 5px 10%;
    margin: 0;
    display: block;
    align-items: center;
    text-align: center;
}
.firstTextAbout2{
    width: 100%;
    padding: 0;
    margin: 5% 0 4% 0;
    display: flex;
    align-items: center;
    text-align: center;
    height: 500px;
    background: url('../imgs/imageWebsite/pexels-pixabay-356056.jpg');
    background-attachment: fixed;
    background-size: cover;
}

.firstTextTitleAbout{
    margin: 5px 0;
    font-weight: normal;
    font-size: 2.3vw;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    /* text-transform: uppercase; */
}
.firstTextTitleAbout2{
    margin: 5px 0;
    font-weight: normal;
    font-size: 45px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    /* text-transform: uppercase; */
    color: rgb(255, 255, 255);
}
.divMap{
    width: 100%;
    display: flex;
    height: 500px;
    overflow-x: hidden;
    background:rgb(71, 71, 71);
    margin: 0;
    padding-right: 5%;
    overflow-y: hidden;
    z-index: 99;
    /* padding-left: 5%; */
}
.Saas{
     height: 500px !important; 
    /* background: red !important;
    overflow-y: hidden; */ 
}
.divContainWhy{
    z-index: 5;
    padding: 5px 10%;
}
.firstTextContain2{
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    margin: 3% 0;
    color: rgb(255, 255, 255);
    text-align: center;
}
.divContainerMember{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.cardMember{
    position: relative;
    width: 25vw;
    height: 55vh;
    background: rgba(255, 255, 255, 0.068);
    margin: 5% 20px;
    box-shadow: 0px 2px 19px 7px rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.contentMember{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0.8;
    transition: 0.5s;
}
.cardMember:hover .contentMember{
    opacity: 1;
    transform: translateY(-20px);
}
.boxImageMember{
    position: relative;
    width: 15vw;
    height: 15vw;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgba(32, 32, 32, 0.61);
}
.imgMember{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.boxContentInfo h3{
    font-family: 'Poppins', sans-serif;
    color: rgb(29, 29, 29);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 1.6vw;
    text-align: center;
    margin: 20px 0 10px;
    line-height: 1.1em;
}
.boxContentInfo p{
    font-family: 'Poppins', sans-serif;
    color: rgb(29, 29, 29);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2vw;
    text-align: center;
    line-height: 1.1em;
}
.ulSocial{
    position: absolute;
    bottom: 80px;
    display: flex;
}
.liSocial{
    list-style: none;
    margin: 0 10px;
    transform: translateY(50px);
    transition: 0.5s;
    opacity: 0;
}
.cardMember:hover .liSocial{
    transform: translateY(20px);
    opacity: 1;
}
.aSocial{
    color: rgb(26, 26, 26);
    font-size: 24px;
}
@media screen and (max-width: 1034px) {
    .divImgTopAbout{
        height: 100vh;
    }
    .imageTop{
        height: 100vh;
        object-fit: cover;
    }
    .coverTopAbout{
        height: 100vh;
    }
    .textInsideTop{
        margin-bottom: 15%;
        width: 90%;
        font-size: 4vw;
    }
    .titleInsideTopAbout{
        font-size: 8vw;
    }
    .divQualitiesListAbout{
        width: 100%;
        padding: 5% 1% 1% 1%;
    }
    .div1stQualityAbout{
        width: 100%;
        margin: 3% 1%;
        overflow: hidden;
    }
    .divImageQualityAbout{
        width: 100%;
    }
    .div1stQualityAbout:hover .coverImgAbout{
        width: 96%;
        height: 55vh;
        max-width: 96%;
    }
    .divLegendQualityAbout{
        position: absolute;
        width: 90%;
        max-width: 90%;
        font-size: 5vw;
        /* margin-top:100%; */
    }   
    .firstTextTitleAbout2{
        font-size: 8vw;
    }
    .firstTextContain2{
        font-size: 3vw;
    }
    .firstTextTitleAbout{
        font-size: 6vw;
    }
    .cardMember{
        width: 95vw;
        height: 65vh;
    }
    .boxContentInfo h3{
        font-size: 4vw;
    }
    .boxContentInfo p{
        font-size: 3vw;
    }
    .boxImageMember{
        width: 30vh;
        height: 30vh;
    }
}