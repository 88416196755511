.divImgTopServices{
    width: 100%;
    height: 630px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    background: rgb(252, 252, 252);
}

.imageTopServices{
    width: 100%;
    height: 750px;
    transform: scale(1);
    object-fit: cover;
}
.coverTopServices{
    position:absolute;
    z-index: 2;
    width: 100%;
    height: 630px;
    background: rgba(12, 12, 12, 0.411);
}
.textInsideTopServices{
    position:absolute;
    z-index: 4;
    bottom: 0;
    left: 0;
    margin-bottom: 17%;
    margin-left: 7%;
    max-width: 100vw;
    overflow-y: hidden;
    display: block;
    color: rgb(245, 245, 245);
    width: 65%;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 400;
}
.titleInsideTopServices{
    font-size: 60px;
    color: white;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}
.divServicesList{
    width: 100%;
    padding: 3% 5%;
    margin: 0%;
    display: block;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.titleElementServices{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 2vw;
    margin: 1% 0 2% 0;
}
.divServicesContain{
    width: 100%;
    padding: 1% 1%;
    /* background: cornflowerblue; */
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    justify-content: space-between;
}
.divServicesText{
    width: 43vw;
    display: block;
    text-align: left;
    /* background: lightcoral; */
    flex-direction: column;
    overflow: hidden;
    padding: 13vh 5%;
    align-items: center;
}
.divServicesImage{
    width: 43vw;
    /* background: lightcoral; */
    height: 65vh;
    flex-direction: column;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.serviceListTitle{
    width: 100%;
    margin: 1% 0 5% 0;
    font-weight: 700;
    font-size: 2vw;
    font-family: 'Montserrat', sans-serif;
}
.serviceListInfo{
    width: 100%;
    font-size: 1.5vw;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
.ImageElementServices{
    width: 80%;
    height: 80%;
    object-fit: cover;
}
.divLangages{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* background: cornflowerblue; */
}
.divLanguageInfo{
    width: 17vw;
    height: 30vh;
    /* background: chocolate; */
    margin: 1% 1%;
    flex-direction: column;
}
.divLanguageImage{
    width: 100%;
    height: 23vh;
    /* background: crimson; */
    padding: 2vw;
}
.langagesName{
    /* text-transform: uppercase; */
    font-size: 1.3vw;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7vh;
    font-weight:600;
}
.imageLangage{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media screen and (max-width: 1034px) {
    .divImgTopServices{
        height: 100vh;
    }
    
    .imageTopServices{
        height: 100vh;
        object-fit: cover;
    }
    .coverTopServices{
        height: 100vh;
    }
    .textInsideTopServices{
        width: 90%;
        font-size: 4vw;
    }
    .titleInsideTopServices{
        font-size: 8vw;
    }
    .titleElementServices{
        font-size: 6vw;
    }
    .divServicesText{
        width: 100vw;
        height: 30vh;
        padding: 5vh 5%;
        position: absolute;
        left: 0;
        border-top: 1px solid rgb(34, 34, 34);
    }
    .divServicesImage{
        width: 100%;
        position: absolute;
        right: 0;
        margin-top: 20vh;
        overflow: hidden;
    }
    .serviceListTitle{
        font-size: 5vw;
    }
    .serviceListInfo{
        font-size: 3vw;
    }
    .ImageElementServices{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .divServicesContain{
        padding: 0 2%;
        margin: 1% 0;
        height: 85vh;
        overflow: hidden;
    }
    .divServicesList{
        padding: 2% 1%;
    }
    .divLanguageInfo{
        width: 47%;
    }
    .langagesName{
        font-size: 3vw;
    }
}
